import React, { useState, useCallback } from "react";
import ImageViewer from "react-simple-image-viewer";

export const Gallery = props => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const data = [
    { thumb: "img/portfolio/image1.jpeg", title: "LV land suvrey, Euro School Kukataplly" },
    { thumb: "img/portfolio/image2.jpeg", title: "LV land suvrey, Alampur, Jogulamaba Audtorium" },
    { thumb: "img/portfolio/image3.jpeg", title: "LV land suvrey, Nakrekal Govt Hospital" },
    { thumb: "img/portfolio/image4.jpeg", title: "LV land suvrey, MalabarBuilding , Maheshwaram " },
    { thumb: "img/portfolio/image5.jpeg", title: "LV land suvrey, Secret Lake Park , Durgam Cheruvu" },
    { thumb: "img/portfolio/image6.jpeg", title: "LV land suvrey, Kurnool " },
    { thumb: "img/portfolio/image8.jpeg", title: "LV land suvrey, Jogulamba Temple Auditorium " },
    { thumb: "img/portfolio/image9.png", title: "LV land suvrey, Bhuvanagiri Cricket Stadium Layout " },
  ];

  const images = data.map(obj => obj.thumb.replace("-small", "-large"));

  const openImageViewer = useCallback(index => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Finished Projects</h2>
          <p>WE BRING YOU THE AMAZING SERVICES.</p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {data.map(({ title, thumb }, index) => (
              <div key={index} onClick={() => openImageViewer(index)} className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item cursor">
                  <div className="hover-bg">
                    <div className="hover-text">
                      <h4>{title}</h4>
                    </div>
                    <img src={thumb} className="img-responsive" alt="Project Title" />{" "}
                  </div>
                </div>
              </div>
            ))}
          </div>

          {isViewerOpen && (
            <ImageViewer
              src={images}
              backgroundStyle={{ zIndex: 99999 }}
              currentIndex={currentImage}
              onClose={closeImageViewer}
            />
          )}
        </div>
      </div>
    </div>
  );
};
