import ParticlesBg from "particles-bg";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import bg from "../../public/img/img-bg.jpeg";

export const Header = (props) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    position : "absolute",
    width: "100%",
  };
  return (
    <header id="header">      
      <div className="intro">
        <ParticlesBg type="circle" bg={{zIndex: 0, position:"absolute", top:0}} />        
        <> </>
        <div className="overlay">
          <div className="container">
            <div className="row">              
              <div className="col-md-8 col-md-offset-2 intro-text">                
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <a
                  href="#contact"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Get in touch
                </a>{" "}                
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
