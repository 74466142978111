export const Footer = (props) => {
  
  return (
    <div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; 2023. LV Land Suvey Solutions {''}
            <a href='https://sunilkumarchoudari.github.io' rel='nofollow'>
              Developed by SKC
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
